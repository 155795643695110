import { Constants } from '@yescapa-dev/ysc-api-js/legacy'

export default defineNuxtRouteMiddleware((to) => {
  if (to.name !== 'd') {
    return
  }

  const { isOwner } = storeToRefs(useUserStore())
  if (isOwner.value) {
    const { WAITING, TO_DO } = Constants.BOOKINGS.LISTING_STATE
    const { all: allBookingsOwner } = storeToRefs(useBookingsOwnerStore())
    const { all: allBookingsGuest } = storeToRefs(useBookingsGuestStore())
    if (allBookingsOwner.value[WAITING]?.count || !allBookingsGuest.value[TO_DO]?.count) {
      return navigateTo({ name: 'd-bookings' })
    }
  }

  return navigateTo({ name: 'd-trips' })
})
